import React from 'react'
import get from 'lodash/get'
import { graphql } from 'gatsby'
import styled from '@emotion/styled'

import Layout from '../components/layout'
import SEO from '../components/seo'

import PreviewCase from '../components/case-preview'

const Hero = styled.div`
  ${tw`w-full md:max-w-md xl:max-w-lg md:h-screen-60 flex items-center my-10 md:my-0`};
`
const Title = styled.h1`
  ${tw`text-4xl md:text-5xl text-black font-normal my-4 md:my-6`};
`
const Subline = styled.div`
  ${tw`text-lg md:text-xl w-full md:w-2/3`};
`
const Section = styled.section`
  ${tw``};
`
const Articles = styled.div`
  ${tw`flex flex-wrap my-4 font-sans text-base text-grey`};
`

class IndexPage extends React.Component {
  render() {
    const [siteSettings] = get(this, 'props.data.allContentfulMainTitle.edges')
    const posts = get(this, 'props.data.allContentfulBlogPost.edges')
    const cases = get(this, 'props.data.allContentfulPortfolioEntry.edges')
    return (
      <Layout>
        <SEO
          title="kwaw.co"
          description="Technology agency. Quality On Time."
          keywords={[ `app`, `mobile`, `kwaw.co`, `developer`, `ghana`, `design`, `graphics`, `backend`, `ios`, `android`, `ui`, `ux`,`kwaw`, `amihere`]}
        />
        <Hero>
          <div>
            <Title
              dangerouslySetInnerHTML={{
                __html: siteSettings.node.headline.childMarkdownRemark.html,
              }}
            />
            <Subline
              dangerouslySetInnerHTML={{
                __html: siteSettings.node.subline.childMarkdownRemark.html,
              }}
            />
          </div>
        </Hero>
        <Section id="latestWork">
          <Articles>
            {cases.map(({ node }) => {
              return <PreviewCase entry={node} key={node.slug} />
            })}
          </Articles>
        </Section>
      </Layout>
    )
  }
}

export default IndexPage

export const pageQuery = graphql`
  query HomeQuery {
    site {
      siteMetadata {
        title
      }
    }
    allContentfulPortfolioEntry(
      sort: { fields: [publishDate], order: DESC }
      limit: 6
      filter: { promote: { ne: false } }
    ) {
      edges {
        node {
          title
          clientName
          slug
          publishDate(formatString: "DD . MMMM . GG")
          tags
          heroImage {
            gatsbyImageData(layout: FULL_WIDTH)
          }
          clientColor {
            clientColor
          }
          description
        }
      }
    }
    allContentfulMainTitle {
      edges {
        node {
          headline {
            childMarkdownRemark {
              html
            }
          }
          subline {
            childMarkdownRemark {
              html
            }
          }
        }
      }
    }
  }
`
